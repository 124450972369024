<template>
  <div class="quotationTemplate" :class="{ qutationSmall: SearchType == 1 }">
    <div class="list-header">
      <div class="header-left">
        <div>{{$t('lang.NText314')}}</div>
        <!-- <div class="number">{{ total }}/10</div> -->
      </div>
      <!-- <div class="header-right" v-if="total < 10">
        <el-button class="create" @click="createTemplate()"
          ><div class="jia">+</div>
          Create template</el-button
        >
      </div> -->
    </div>
    <div class="list">
      <dwlist
        :url="source.quotation_api.findTemplateList"
        :query-params="queryForm"
        ref="postsList"
        @onloadSuccess="onloadSuccess"
      >
        <el-table-column type="index" :label="$t('lang.BText98')" width="80"> </el-table-column>
        <el-table-column
          prop="quotationTemplateName"
          :label="$t('lang.QText92')"
          width="180px"
        >
        </el-table-column>

        <el-table-column prop="summry" :label="$t('lang.BText4')">
          <template slot-scope="scope">
            <div class="Summry">
              <div class="line1">
                {{ quotationTypeObj[scope.row.quotationMainType] }} -
                {{ quotationTypeObj[scope.row.quotationType] }}
              </div>
              <div class="line2">
               <span v-if="scope.row.fromType == 0">{{
                scope.row.fromCyPortName
              }}</span>
              <span v-if="scope.row.fromType == 1"
                >{{ scope.row.fromDoorCityName }},{{
                  scope.row.fromDoorCountry
                }}</span
              >
              <span style="color: #299be4"> -> </span>
              <span v-if="scope.row.toType == 0">{{
                scope.row.toCyPortName
              }}</span>
              <span v-if="scope.row.toType == 1"
                >{{ scope.row.toDoorCityName }},{{
                  scope.row.toDoorCountry
                }}</span
              >
              </div>
              <div class="line3" v-if="scope.row.containerDatas !== ''">
                <div
                  v-for="(item, index) in JSON.parse(scope.row.containerDatas)"
                  :key="index"
                >
                  {{ item.containerSize }}*{{ item.count }};
                </div>
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column fixed="right" width="240px">
          <template slot-scope="scope">
            <div class="RoleRight2">
              <el-button
                @click="getAQuote(scope.row)"
                class="samll-list-primary"
                >{{$t('lang.BText120')}}</el-button
              >
              <el-button
                @click="showMore(scope.row, scope.$index)"
                class="samll-list-info"
                >{{$t('lang.BText109')}}<svg-icon class="icon" icon-class="downTriangle"></svg-icon
              ></el-button>
            </div>
          </template>
        </el-table-column>
      </dwlist>
      <div
        v-show="signOut"
        class="signOutHover"
        :style="{ top: 0.6 * (thisRow.index - 1) + 0.7 + 'rem' }"
      >
        <div class="list-button">
          <div class="list-button-item" @click="edit(thisRow)">{{$t('lang.QText77')}}</div>
          <div class="list-button-item" @click="click_Delete(thisRow)">
            {{$t('lang.BText40')}}
          </div>
          <div class="list-button-item" @click="click_Rename(thisRow)">
            {{$t('lang.BText55')}}
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      v-if="showEditFLag"
      :visible.sync="showEditFLag"
      class="editDialog dw-dialog"
      width="70%"
      @close="closeDialog"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialog-header">
        <div class="dw-logo">
<!--			<svg-icon icon-class="dwLogo"></svg-icon>-->
		</div>
        <div class="dialog-title">
          <div class="row1 blod60">{{ dialogTitle }}</div>
          <div class="row2" v-if="thisRow.quotationNo">
            No {{ thisRow.index }}:<span style="color: #fff">
              {{ thisRow.quotationTemplateName }}</span
            >
          </div>
        </div>

        <div class="close" @click="closeDialog">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <div class="el-dialog-main" ref="dwDialogMain">
        <edit-dialog
          v-if="showEdit"
          :quotationData="thisRow"
          @editReview="editReview"
          @cancelEdit="closeDialog"
        ></edit-dialog>
        <review
          v-if="showReview"
          :quotationData="JSON.stringify(thisRow)"
          @edit="clickEdit"
          @SaveSuccess="closeDialog"
        ></review>
      </div>
    </el-dialog>

    <dw-check-box
      :showCheckBox="showSaveTemplate"
      @Cancel="showSaveTemplate = false"
      @Save="templateRename"
      :primaryButton="$t('lang.SText13')"
      :CancelButton="$t('lang.QText79')"
    >
      {{$t('lang.QText92')}} *
      <el-form :model="template">
        <el-form-item
          class="form-item"
          prop="templateName"
          label=""
          :rules="[
            {
              required: true,
              message: $t('lang.SText143'),
            },
            { max: 20, message: $t('lang.SText154') },
          ]"
        >
          <el-input v-model="template.templateName"></el-input>
        </el-form-item>
      </el-form>
    </dw-check-box>
    <dw-check-box
      :showCheckBox="showDelete"
      @Cancel="showDelete = false"
      @Save="deleteQuotation"
      :primaryButton="$t('lang.BText40')"
      :CancelButton="$t('lang.QText79')"
    >
      {{ $t("lang.delete") }}
    </dw-check-box>
    <!-- <el-dialog
      :visible.sync="showReview"
      class="createDialog"
      width="70%"
      @close="showReview = false"
    >
      <div class="dialog-header">
        <div class="dw-logo"><svg-icon icon-class="dwLogo"></svg-icon></div>
        <div class="dialog-title">
          <div class="row1 blod60">Quotation review</div>
          <div class="row2">Linked Quote ID:</div>
        </div>

        <div class="close" @click="showReview = false">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <detail-dialog></detail-dialog>
    </el-dialog> -->
  </div>
</template>

<script>
import DwCheckBox from "../../../components/dwCheckBox/dwCheckBox.vue";
import dwlist from "../../../components/dwList/dwlist.vue";
import test from "../../../test/test";
import utils from "../../../utils/util";
import editDialog from "../aQuote/edit.vue";
import Review from "../aQuote/Review.vue";
export default {
  components: {
    dwlist,
    DwCheckBox,
    editDialog,
    Review,
  },
  data() {
    return {
      listData: test.listData,
      listloading: false,
      queryForm: {},
      userInfo: {},
      showDelete: false,
      showSaveTemplate: false,
      template: {},
      total: 0,
      quotationTypeObj: {},
      thisRow: {},
      signOut: false,
      showEditFLag: false,
      dialogTitle: "",
      showReview: false,
      showEdit: false,
    };
  },

  created() {
    this.$session("userInfo").get((value) => {
      this.queryForm.companyId = value.companyid;
    });
    this.$session("quotationType").get((value) => {
      value.forEach((item) => {
        this.quotationTypeObj[item.dictValue] = item.dictLabel;
        if (item.children.length > 0) {
          item.children.forEach((item2) => {
            this.quotationTypeObj[item2.dictValue] = item2.dictLabel;
          });
        }
      });
    });
  },
  methods: {
    query() {
      this.$refs.postsList.query();
    },
    onloadSuccess(data) {
      this.total = data.total;
    },
    edit() {
      this.showEditFLag = true;
      this.showEdit = true;
      this.signOut = false;
      this.dialogTitle = this.$t('lang.NText326');
    },
    showMore(data, index) {
      let Index = index + 1;
      if (!this.thisRow.index || this.thisRow.index != Index) {
        this.thisRow = data;
        this.signOut = true;
        this.thisRow.index = Index;
        return;
      } else {
        this.signOut = false;
        this.thisRow = {};
      }
    },
    click_Delete(data) {
      this.quotationId = data.id;
      this.showDelete = true;
      this.signOut = false;
    },
    deleteQuotation() {
      this.$ajax
        .cPost(this.source.quotation_api.deleteQuotation, {
          quotationId: this.quotationId,
        })
        .then((res) => {
          if (res.data.result == "true") {
            this.$notify.success({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
            this.showDelete = false;
            this.query();
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    click_Rename(data) {
      this.$set(this.template, "templateName", "");
      this.template.quotationId = data.id;
      this.showSaveTemplate = true;
      this.signOut = false;
    },
    templateRename() {
      this.$ajax
        .cPost(this.source.quotation_api.templateRename, this.template)
        .then((res) => {
          if (res.data.result == "true") {
            this.$notify.success({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
            this.showSaveTemplate = false;
            this.query();
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    // 获取服务数据
    setServices(e) {
      let data = e;
      data.forEach((item, index) => {
        switch (item.serviceType) {
          case "0":
            this.template.originPickUpFlag = "1";
            break;
          case "1":
            this.template.originCustomsClearFlag = "1";
            break;
          case "2":
            this.template.originInsurFlag = "1";
            this.template.originInsurVlaue = item.insurVlaue;
            this.template.originInsurCurr = item.insurCurr;
            break;
          case "3":
            this.template.originBondFlag = "1";
            this.template.originBondType = item.bondType;
            break;
          case "4":
            this.template.originIsfFlag = "1";
            break;
          case "5":
            this.template.originWareFlag = "1";
            this.template.originWareServices = item.warehouse;
            break;
          case "6":
            this.template.destinationDelivFlag = "1";
            break;
          case "7":
            this.template.destinationCustomsClearFlag = "1";
            break;
          case "8":
            this.template.destinationBondFlag = "1";
            this.template.destinationBondType = item.bondType;
            break;
          case "9":
            this.template.destinationWareFlag = "1";
            this.template.destinationWareServices = item.warehouse;
            break;
          case "10":
            this.template.originAmsFlag = "1";
            break;
          case "11":
            this.template.originAciFlag = "1";
            break;
          case "12":
            this.$set(this.template, "originDocFlag", "1");
            break;

          default:
            break;
        }
      });
    },
    Review(data) {
      this.template = data;
      if (typeof this.template.containerDatas == "string") {
        this.template.containerDatas = JSON.parse(this.template.containerDatas);
      } else {
        this.template.containerDatas = this.template.containerDatas;
      }
      if (
        this.template.dangerousGoodsType !== "" &&
        typeof this.template.dangerousGoodsType == "string"
      ) {
        this.template.dangerousGoodsType = JSON.parse(
          this.template.dangerousGoodsType
        );
      } else if (this.template.dangerousGoodsType == "") {
        this.template.dangerousGoodsType = [];
      }
      // if (this.$i18n.locale == "en_us" && this.template.id) {
      //   this.template.deliveryDate = utils.enDate_to_zhDate(
      //     this.template.deliveryDate
      //   );
      //   this.template.cargoReadyDate = utils.enDate_to_zhDate(
      //     this.template.cargoReadyDate
      //   );
      // }
      if (data.services && data.services.length > 0) {
        this.setServices(data.services);
      }
      this.$router.push({
        name: "Review",
        params: { submitForm: this.template, templateFlag: true },
      });
    },
    Booking(row) {
      var form = {
        dataSource: "1",
        airSea: row.quotationMainType,
        bookingType: row.quotationType,
        cargoReadyDay: row.cargoReadyDate,
        destinationTargetDeliveryDate: row.deliveryDate,
        totalVolume: row.totalVolume,
        totalVolumeUnit: row.totalVolumeUnit,
        totalWeight: row.totalWeight,
        totalWeightUnit: row.totalWeightUnit,
        totalQuantity: row.totalQuantity,
        totalQuantityUnit: row.totalQuantitUnit,
        incotermOne: row.incotermsType,
        incotermTwo: row.incotermsDataType,
        cargoType: row.cargoType,
        cargoDetails: "1",
        commodityHscodes: row.commodityHscodes,
        notificationRemark: row.masks,
        quotationNo: row.quotationNo,
        quotationId: row.id,
      };
      // if (this.$i18n.locale == "en_us") {
      //   form.destinationTargetDeliveryDate = utils.enDate_to_zhDate(
      //     form.destinationTargetDeliveryDate
      //   );
      //   form.cargoReadyDay = utils.enDate_to_zhDate(form.cargoReadyDay);
      // }
      form.containerType = [];
      if (row.containerDatas !== "" && row.containerDatas) {
        let bb = JSON.parse(row.containerDatas);
        bb.forEach((item, index) => {
          let cc = {
            containerTypOne: item.containerSize,
            containerTypTwo: item.count,
          };
          form.containerType.push(cc);
        });
      }
      if (row.dangerousGoodsType !== "" && row.dangerousGoodsType) {
        let aa = JSON.parse(row.dangerousGoodsType);
        form.totalBatteries = aa.indexOf("0") == -1 ? "0" : "1";
        form.hazardousMaterial = aa.indexOf("1") == -1 ? "0" : "1";
        form.totalOther = aa.indexOf("2") == -1 ? "0" : "1";
        form.lithiumBattery = aa.indexOf("3") == -1 ? "0" : "1";
      }
      if (row.services && row.services.length > 0) {
        row.services.forEach((item, index) => {
          switch (item.serviceType) {
            case "0":
              form.originPort = "1";
              break;
            case "1":
              form.originCustomerClearance = "1";
              break;
            case "2":
              form.originInsurance = "1";
              form.originValue = item.insurVlaue;
              form.originCurrency = item.insurCurr;
              break;
            case "3":
              form.originBondFlag = "1";
              form.originBondType = item.bondType;
              break;
            case "4":
              form.originIsfFlag = "1";
              break;
            case "5":
              form.originWarehouse = "1";
              form.originValueAddedSevice = item.warehouse;
              break;
            case "6":
              form.destinationDischargePort = "1";
              break;
            case "7":
              form.customerClearance = "1";
              break;
            case "8":
              form.destinationBound = "1";
              if (item.bondType == "1") {
                form.destinationBoundValue = "Single bond";
              } else {
                form.destinationBoundValue = "Annual bond";
              }
              break;
            case "9":
              form.destinationWarehouse = "1";
              form.destinationWarehouseValue = item.warehouse;
              break;
            case "10":
              form.originAmsFlag = "1";
              break;
            case "11":
              form.originAciFlag = "1";
              break;
            case "12":
              form.originDocFlag = "1";
              break;

            default:
              break;
          }
        });
      }
      this.$router.push({
        name: "Booking",
        params: { bookingData: form, fillInFormQ: true },
      });
    },
    getAQuote(row, column, event) {
      var form = {
        quotationMainType: row.quotationMainType,
        quotationType: row.quotationType,
        fromType: row.fromType,
        fromCyPort: row.fromCyPort,
        fromDoorAddress: row.fromDoorAddress,
        fromDoorCountry: row.fromDoorCountry,
        fromDoorCity: row.fromDoorCity,
        fromDoorState: row.fromDoorState,
        fromDoorZipCode: row.fromDoorZipCode,
        toType: row.toType,
        toCyPort: row.toCyPort,
        toDoorAddress: row.toDoorAddress,
        toDoorCountry: row.toDoorCountry,
        toDoorCity: row.toDoorCity,
        toDoorState: row.toDoorState,
        toDoorZipCode: row.toDoorZipCode,
        cargoReadyDate: row.cargoReadyDate,
        deliveryDate: row.deliveryDate,
        containerDatas: row.containerDatas,
        totalVolume: row.totalVolume,
        totalVolumeUnit: row.totalVolumeUnit,
        totalWeight: row.totalWeight,
        totalWeightUnit: row.totalWeightUnit,
        totalQuantity: row.totalQuantity,
        totalQuantitUnit: row.totalQuantitUnit,
        incotermsType: row.incotermsType,
        incotermsDataType: row.incotermsDataType,
        cargoType: row.cargoType,
        commodityHscodes: row.commodityHscodes,
        masks: row.masks,
        dangerousGoodsType: row.dangerousGoodsType,
        packingList: row.packingList,
        destinationBondFlag: row.destinationBondFlag,
        destinationBondType: row.destinationBondType,
        destinationWareFlag: row.destinationWareFlag,
        destinationWareServices: row.destinationWareServices,
        fromDoorPerson: row.fromDoorPerson,
        fromDoorTelephone: row.fromDoorTelephone,
        toDoorPerson: row.toDoorPerson,
        toDoorTelephone: row.toDoorTelephone,
      };
      // if (this.$i18n.locale == "en_us") {
      //   form.deliveryDate = utils.enDate_to_zhDate(form.deliveryDate);
      //   form.cargoReadyDate = utils.enDate_to_zhDate(form.cargoReadyDate);
      // }
      if (row.containerDatas !== "" && row.containerDatas) {
        form.containerDatas = JSON.parse(row.containerDatas);
      }
      if (row.dangerousGoodsType !== "" && row.dangerousGoodsType) {
        form.dangerousGoodsType = JSON.parse(row.dangerousGoodsType);
      }
      if (row.services && row.services.length > 0) {
        row.services.forEach((item, index) => {
          switch (item.serviceType) {
            case "0":
              form.originPickUpFlag = "1";

              break;
            case "1":
              form.originCustomsClearFlag = "1";
              break;
            case "2":
              form.originInsurFlag = "1";
              form.originInsurVlaue = item.insurVlaue;
              form.originInsurCurr = item.insurCurr;
              break;
            case "3":
              form.originBondFlag = "1";
              form.originBondType = item.bondType;
              break;
            case "4":
              form.originIsfFlag = "1";
              break;
            case "5":
              form.originWareFlag = "1";
              form.originWareServices = item.warehouse;
              break;
            case "6":
              form.destinationDelivFlag = "1";
              break;
            case "7":
              form.destinationCustomsClearFlag = "1";
              break;
            case "8":
              form.destinationBondFlag = "1";
              form.destinationBondType = item.bondType;
              break;
            case "9":
              form.destinationWareFlag = "1";
              form.destinationWareServices = item.warehouse;
              break;
            case "10":
              form.originAmsFlag = "1";
              break;
            case "11":
              form.originAciFlag = "1";
              break;
            case "12":
              form.originDocFlag = "1";
              break;

            default:
              break;
          }
        });
      }
      this.dialogTitle = this.$t("lang.QText89");
      this.thisRow = form;
      this.showEditFLag = true;
      this.showEdit = true;
      this.signOut = false;
    },
    editReview(e) {
      this.quotationData = JSON.stringify(e.submitForm);
      this.thisRow = e.submitForm;
      this.clickReview();
    },
    clickReview() {
      this.showReview = true;
      this.showEdit = false;
      this.signOut = false;
    },
    clickEdit() {
      this.showReview = false;
      this.showEdit = true;
      this.signOut = false;
    },
    closeDialog() {
      this.showEditFLag = false;
      this.showReview = false;
      this.showEdit = false;
      this.thisRow = {};
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/base.scss";
.quotationSmall{
  width: 860px;
}
.quotationTemplate {
  padding-bottom: 100px;
  position: relative;
  .list-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .header-left {
      display: flex;
      color: $mainColor;
      font-size: 30px;
      .number {
        font-size: 20px;
        line-height: 35px;
        margin-left: 30px;
      }
    }
    .header-right {
      .el-button {
        height: 60px;
        font-size: 18px;
        color: $mainColor;
        border: 2px solid $mainColor;
        border-radius: 0;
        font-family: "montserratregular";
      }
      .el-button:hover {
        background-color: $background2;
        color: #fff;
        border: 2px solid $mainColor;
      }
      .create {
        background-color: $background2;
        color: #fff;
        border: 2px solid $background2;
        width: 210px;
        height: 60px;
        padding: 0;
        text-align: center;
        line-height: 60px;
        .jia {
          font-size: 30px;
          width: 10px;
          float: left;
          margin-left: 10px;
        }
      }
    }
  }
  .Summry {
    .line1 {
      font-size: 18px;
      color: $background2;
      border-bottom: 1px solid $borderColor1;
      padding-bottom: 10px;
      margin-bottom: 10px;
      font-weight: bold;
    }
    .line2 {
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .line3 {
      display: flex;
    }
  }
  .RoleRight {
    display: flex;
    justify-content: space-between;
  }

  .el-table__cell {
    vertical-align: top;
  }
  .RoleRight2 {
    display: flex;
    justify-content: end;

    .icon {
      width: 10px;
      height: 10px;
      margin-left: 5px;
      margin-top: -5px;
    }
  }
  .dialog-header {
    height: 105px;
    padding: 20px;
    background-color: $mainColor;
    display: flex;
    .dw-logo {
      width: 70px;
      height: 70px;
      padding: 15px;
      .svg-icon {
        width: 70px;
        height: 70px;
        color: #fff;
      }
    }
    .close {
      width: 50px;
      height: 50px;
      margin-top: 30px;
      margin-left: 100px;
      cursor: pointer;
      .close-icon {
        width: 50px;
        height: 50px;
        fill: #fff;
        stroke: #fff;
      }
    }
    .dialog-title {
      color: #fff;
      margin-left: 90px;
      text-align: left;
      width: 900px;
      .row1 {
        margin-top: 5px;
      }
      .row2 {
        margin-top: 14px;
        color: $fontColor1;
      }
    }
    .titleDate {
      display: flex;
      border-bottom: 2px solid #fff;
      padding-top: 20px;
      text-align: left;
      height: 60px;
      .updateDate {
        .date {
          font-size: 20px;
          line-height: 30px;
          color: #fff;
          margin-right: 20px;
        }
        .date2 {
          font-size: 20px;
          line-height: 30px;
          color: $background2;
        }
      }
    }
  }
  .signOutHover {
    position: absolute;
    right: 16px;
    top: 0px;
    height: 100px;
    width: 340px;
    z-index: 9999;
    .list-button {
      position: absolute;
      right: 0;
      top: 29px;
      background-color: #fff;
      font-size: 16px;
      text-align: right;
      line-height: 35px;
      color: $mainColor;
      cursor: pointer;
      padding: 10px;
      font-family: "montserratLight";
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      border-radius: 3px;
    }
    .list-button-item {
      padding: 0 5px;
    }
    .list-button-item:hover {
      color: $background2;
    }
  }
  .signOut-icon1 {
    color: $fontColor2;
    font-size: 40px;
    cursor: pointer;
    text-align: right;
    padding-right: 10px;
    line-height: 30px;
  }
  .signOut-icon {
    color: $mainColor;
    width: 50px;
    height: 30px;
    text-align: right;
    background-color: $background1;
    border-top: 1px solid $borderColor1;
    border-left: 1px solid $borderColor1;
    border-right: 1px solid $borderColor1;
    z-index: 99;
    padding-right: 10px;
  }
}
</style>
